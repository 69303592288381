import { classNames } from '../../../../../../lib/utils';

export default function ColDefault({ children, evenRow = true }) {
  return (
    <div
      className={classNames(
        evenRow ? 'bg-theme-intel-table-gray' : 'bg-theme-intel-2020-e-gray-t2',
        'col-span-1 py-2.5 px-1 md:px-2.5 text-center flex text-srt-table-sm md:text-srt-table-base items-center justify-center text-theme-intel-black font-normal'
      )}
    >
      {children}
    </div>
  );
}
