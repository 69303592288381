import { useParams } from 'react-router-dom';
import { ReactComponent as StarSet } from '../../../images/hero/star_set.svg';
import { ReactComponent as QuantityCounter } from '../../../images/hero/quantity_counter.svg';
import {
  classNames,
  formatBrand,
  formatPriceAndCurrency,
  formatSKU,
  formatTitle,
  isExtPath
} from '../../../lib/utils';
import SectionContainerDefault from '../../container/section-container/default/SectionContainerDefault';
import styles from './Hero.module.css';
import CartIcon from '../../svg/cart-icon/CartIcon';

export default function Hero({
  name,
  price,
  currency,
  display,
  processor,
  ssd,
  hdd,
  emmc,
  ram,
  image,
  sku,
  brand,
  country
}) {
  const { appName } = useParams();
  let formattedPriceAndCurrency = formatPriceAndCurrency(
    country,
    price,
    currency
  );

  /** One-off solution for `rec` Spain event
   * that takes standard Spain format and:
   * 1. replaces decimals with spaces as thousands separator for price
   * 2. swaps currency symbol from right side to left
   */
  if (appName === 'rec') {
    const priceWithSpaces = [...formattedPriceAndCurrency]
      .map((ch) => (ch === '.' ? ' ' : ch))
      .join('');
    const symbol = priceWithSpaces.slice(-1);
    // remove trailing space and symbol
    const priceWithoutSymbol = priceWithSpaces.substring(
      0,
      priceWithSpaces.length - 2
    );
    formattedPriceAndCurrency = [priceWithoutSymbol, symbol].join(' ');
  }

  const formattedTitle = formatTitle(
    name,
    display,
    processor,
    ssd,
    hdd,
    emmc,
    ram
  );

  const formattedSKU = formatSKU(sku);
  const formattedBrand = formatBrand(brand);

  // NOTE: The image displayed below is expected to be either an
  // external image URL eg. `http...` or a file name for the file
  // found at the relative path using the convention below:
  //  `${process.env.PUBLIC_URL}/data/${appName}/assets/images/${image}`

  // EXAMPLE
  // A full file path for an image: ${process.env.PUBLIC_URL}/data/us/assets/images/${process.env.PUBLIC_URL}/data/94029441_5992640209.jpg
  // The file name expected in the image field in JSON feed:
  // "image": "94029441_5992640209.jpg"

  return (
    <section className='font-intel-one-display'>
      <SectionContainerDefault>
        <div className='grid grid-cols-1 pt-4 sm:pt-0 gap-y-10 sm:grid-cols-2 gap-x-[100px] xl:gap-x-0'>
          <div className='order-2 sm:order-1 flex flex-col px-0 xl:px-[120px]'>
            <div className='flex flex-none sm:min-h-0 sm:flex-1 justify-center'>
              {image ? (
                <img
                  key={image}
                  className='h-full object-contain'
                  src={
                    isExtPath(image)
                      ? image
                      : `${process.env.PUBLIC_URL}/data/${appName}/assets/images/${image}`
                  }
                  alt={`${name}`}
                />
              ) : (
                <div>Image not available</div>
              )}
            </div>
          </div>

          <div className='text-theme-intel-2020-e-gray-s2 order-1 sm:order-2 grid grid-cols-1 py-4 sm:py-10 gap-y-[10px]'>
            <h1 className='text-srt-xl sm:text-srt-2xl font-medium'>
              {formattedTitle}
            </h1>
            <div className='text-srt-xxs sm:text-srt-sm flex gap-x-2.5'>
              {formattedBrand && (
                <div>
                  <span className='font-bold'>Brand: </span>
                  <span>{formattedBrand}</span>
                </div>
              )}
              {formattedSKU && (
                <div>
                  <span className='font-bold'>Model: </span>
                  <span>{formattedSKU}</span>
                </div>
              )}
            </div>
            {formattedPriceAndCurrency && (
              <div className='font-bold text-srt-xl sm:text-srt-2xl'>
                {formattedPriceAndCurrency}
              </div>
            )}
            <div>
              <span className='sr-only'>Star Rating</span>
              <StarSet className='h-[15px] sm:h-[26px] w-auto' />
            </div>
            <div className='border-t border-b border-theme-intel-2020-e-gray-t1 flex flex-wrap gap-x-4 gap-y-5 lg:gap-x-10 lg:justify-center px-0 py-4 lg:py-[30px] lg:px-4 xl:px-12 xl:mr-[76px]'>
              <div className='flex-none'>
                <span className='sr-only'>Item Quantity Button</span>
                <QuantityCounter />
              </div>
              <button
                type='button'
                className={classNames(
                  styles['cart-button'],
                  'flex-none bg-theme-intel-2020-cobalt-blue flex items-center gap-x-2 rounded-[4px] py-2 px-10'
                )}
              >
                <CartIcon
                  pathStyles={styles['fill-path']}
                  className={styles['cart-icon']}
                />
                <span className='text-white font-medium text-srt-xs'>
                  Add to cart
                </span>
              </button>
              <div className='flex items-center sm:basis-full md:basis-auto lg:flex-none'>
                <span className='font-medium font-intel-one-text text-srt-xs text-theme-intel-2020-e-gray-s1'>
                  In Stock
                </span>
              </div>
            </div>
          </div>
        </div>
      </SectionContainerDefault>
    </section>
  );
}
