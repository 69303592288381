import DetailGridItem from '../detail-grid-item/DetailGridItem';

export default function DetailGrid({ items }) {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 mb-16 mb:mb-20 gap-y-16 md:gap-y-20 md:gap-x-5 lg:gap-x-6 xl:gap-x-16'>
      {items.map(
        (item) =>
          item.image && (
            <DetailGridItem
              key={item.value}
              title={item.title}
              value={item.value}
              image={item.image}
            />
          )
      )}
    </div>
  );
}
