import { classNames } from '../../../../../../lib/utils';

export default function ColFirst({ children, evenRow = true }) {
  return (
    <div
      className={classNames(
        evenRow ? 'bg-theme-intel-table-gray' : 'bg-theme-intel-2020-e-gray-t2',
        'col-span-2 py-2.5 px-1 md:px-2.5 text-theme-intel-black font-medium text-srt-table-sm md:text-srt-table-base text-right grid grid-cols-1 items-center'
      )}
    >
      {children}
    </div>
  );
}
