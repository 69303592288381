import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Product from './pages/product/Product';
import ProductsDirectory from './pages/products-directory/ProductsDirectory';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import AppPage from './pages/app-page/AppPage';
import NotFound from './pages/404/NotFound';

function App() {
  return (
    <Router>
      <div className='flex flex-col min-h-[100vh] w-full'>
        <Header />
        <Routes>
          <Route path='/' element={<NotFound />} />
          <Route path=':appName/*' element={<AppPage />}>
            <Route path='products/:productId' element={<Product />} />
            <Route
              path='srt/pages/admin-view'
              element={<ProductsDirectory />}
            />
          </Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
