import { Link } from 'react-router-dom';
import Container from '../../container/Container';
import { validProductsJSON } from '../../../lib/utils';


export default function Nav({ products, productId, appName }) {
  // if we have valid products, show nav
  const ids = validProductsJSON(products) && Object.keys(products);
  const firstProductId = ids && ids[0];

  if (ids && productId && appName) {
    const currentIndex = ids.indexOf(productId);
    const nextId = ids[currentIndex + 1];
    const prevId = ids[currentIndex - 1];

    return (
      <Container>
        <div className='flex gap-4 -mb-6'>
          <div>
            <Link
              className='text-blue-500 hover:text-blue-500 hover:underline'
              to={`/${appName}/products/${firstProductId}`}
            >
              Home
            </Link>
          </div>
          <div className='flex gap-4'>
            {prevId && (
              <Link
                className='text-blue-500 hover:text-blue-500 hover:underline'
                to={`/${appName}/products/${prevId}`}
              >
                Prev
              </Link>
            )}
            {nextId && (
              <Link
                className='text-blue-500 hover:text-blue-500 hover:underline'
                to={`/${appName}/products/${nextId}`}
              >
                Next
              </Link>
            )}
          </div>
        </div>
      </Container>
    );
  }
}
