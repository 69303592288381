export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function isStringAndTruthy(val) {
  return typeof val === 'string' && val !== '';
}

export const isArray = (val) =>
  Object.prototype.toString.call(val) === '[object Array]';

export const isObject = (val) =>
  Object.prototype.toString.call(val) === '[object Object]';

/**
 * Gets a de-duped list of all keys at any level of some nested JSON
 * @param {*} data JSON
 * @returns {string[]} a list of keys
 */
export function getAllKeys(data) {
  const results = [];

  const checkKeys = (d) => {
    if (isArray(d)) {
      d.forEach((item) => {
        if (isArray(item)) {
          checkKeys(item);
        } else if (isObject(item) && item !== null) {
          Object.keys(item).forEach((key) => {
            if (!results.includes(key)) {
              results.push(key);
            }
            if (
              isArray(item[key]) ||
              (isObject(item[key]) && item[key] !== null)
            ) {
              checkKeys(item[key]);
            }
          });
        }
      });
    } else if (isObject(d) && d !== null) {
      Object.keys(d).forEach((key) => {
        if (!results.includes(key)) {
          results.push(key);
        }
        if (isArray(d[key]) || (isObject(d[key]) && d[key] !== null)) {
          checkKeys(d[key]);
        }
      });
    }
  };

  checkKeys(data);

  return results;
}

/**
 * Very basic way to inspect the JSON to ensure it looks like what we expect.
 * Let's check that it's an object with strings as keys for starters.
 * @param {*} data
 * @returns {boolean} whether or not the JSON appears valid
 */
export function validProductsJSON(data) {
  return (
    isObject(data) &&
    Object.keys(data).filter((key) => typeof key !== 'string').length === 0
  );
}

/**
 * Check whether image is an external URL beginning with `http...`
 * @param {string} path
 * @returns {boolean} true if external URL, false if relative path
 */
export function isExtPath(path) {
  return !!path.match(/^http.*$/g);
}

/**
 * Default filter to remove certain products.
 * @param {object} details product details
 * @returns {boolean} true if external URL, false if relative path
 */
export function shouldIncludeProduct(details) {
  return (
    !details?.os.match(/^.*Windows\s10.*$/g) &&
    !details?.processor.match(/^.*Intel.*Pentium.*$|^.*Intel.*Celeron.*$/g)
  );
}

export function formatDisplay(display) {
  return isStringAndTruthy(display) ? `${display}"` : '';
}

export function formatSsd(ssd) {
  const num = Number(ssd);
  if (!Number.isNaN(num) && ssd !== '') {
    if (num >= 1000 && num % 1000 === 0) {
      return `${num / 1000} TB SSD`;
    }

    return `${ssd} GB SSD`;
  }

  return null;
}

// Used for product details and specs - `GB RAM` rather than `GB Memory`
export function formatRam(ram) {
  const num = Number(ram);
  if (!Number.isNaN(num) && ram !== '') {
    return `${ram} GB RAM`;
  }

  return null;
}

// Used in title - `GB Memory` rather than `GB RAM`
export function formatMemory(ram) {
  const num = Number(ram);
  if (!Number.isNaN(num) && ram !== '') {
    return `${ram} GB Memory`;
  }

  return null;
}

export function formatHdd(hdd) {
  const num = Number(hdd);
  if (!Number.isNaN(num) && hdd !== '') {
    if (num >= 1000 && num % 1000 === 0) {
      return `${num / 1000} TB HDD`;
    }

    return `${hdd} GB HDD`;
  }

  return null;
}

export function formatEmmc(emmc) {
  const num = Number(emmc);
  if (!Number.isNaN(num) && emmc !== '') {
    if (num >= 1000 && num % 1000 === 0) {
      return `${num / 1000} TB EMMC`;
    }

    return `${emmc} GB EMMC`;
  }

  return null;
}

export function formatResolution(resolution) {
  if (isStringAndTruthy(resolution)) {
    return resolution;
  }

  return null;
}

export function formatSKU(sku) {
  if (isStringAndTruthy(sku)) {
    return sku;
  }

  return null;
}

export function formatPriceAndCurrency(country, price, currency) {
  const num = Number(price);
  if (!Number.isNaN(num) && price !== '') {
    if (isStringAndTruthy(currency) && isStringAndTruthy(country)) {
      return new Intl.NumberFormat(country, {
        style: 'currency',
        currency
      }).format(num.toFixed(2));
    }

    return new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD'
    }).format(num.toFixed(2));
  }

  return null;
}

export function formatBrand(brand) {
  if (!isStringAndTruthy(brand)) {
    return null;
  }

  return brand;
}

export function formatBatteryLife(batLife) {
  const num = Number(batLife);
  if (!Number.isNaN(num) && batLife !== '') {
    return `${batLife} hours`;
  }

  return null;
}


export function formatWeight(weight, config) {
  const num = Number(weight);
  
  // console.log(config)
  if(config.weight_format === 'metric'){
    // eslint-disable-next-line
    // console.log('num ->',num);
    if (!Number.isNaN(num) && weight !== '') {
      return `${num.toFixed(2)} kg`;
    }
  }
  else if(config.weight_format === 'standard'){
    // 2.20462
    return `${num.toFixed(2) } lbs`;
  }
  return null;
}

export function formatNumPorts(numPorts) {
  const num = Number(numPorts);
  if (!Number.isNaN(num) && numPorts !== '' && num !== 0) {
    return `${numPorts} Thunderbolt 4 ${numPorts === '1' ? 'port' : 'ports'}`;
  }

  return null;
}

export function formatGameTitle(title) {
  if (title === 'wow') {
    return 'World of Warcraft';
  }
  if (title === 'fortnite') {
    return 'Fortnite';
  }
  if (title === 'gtav') {
    return 'Grand Theft Auto 5';
  }
  if (title === 'destiny2') {
    return 'Destiny 2';
  }
  if (title === 'lol') {
    return 'League of Legends';
  }
  if (title === 'pubg') {
    return 'PUBG: Battlegrounds';
  }
  if (title === 'csgo') {
    return 'Counter-strike: Global Offensive';
  }
  if (title === 'hitman3') {
    return 'Hitman 3';
  }
  if (title === 'valorant') {
    return 'Valorant';
  }
  if (title === 'escapefromtarkov') {
    return 'Escape from Tatris';
  }
  if (title === 'cod') {
    return 'Call of Duty';
  }

  return null;
}

/**
 *
 * @param {string} ssd
 * @param {string} hdd
 * @param {string} emmc
 * @returns {string | null} returns a formatted storage value or null
 */
export function getStorageValue(ssd, hdd, emmc) {
  // If we have ssd, use it, else use hdd, else use emmc.
  if (isStringAndTruthy(ssd)) {
    return formatSsd(ssd);
  }
  if (isStringAndTruthy(hdd)) {
    return formatHdd(hdd);
  }
  if (isStringAndTruthy(emmc)) {
    return formatEmmc(emmc);
  }
  return null;
}

/**
 *
 * @param {string} ssd
 * @param {string} hdd
 * @param {string} emmc
 * @returns {string | null} returns the unit type that should be used for storage
 */
export function getStorageType(ssd, hdd, emmc) {
  // If we have ssd, use it, else use hdd, else use emmc.
  if (isStringAndTruthy(ssd)) {
    return 'SSD';
  }
  if (isStringAndTruthy(hdd)) {
    return 'HDD';
  }
  if (isStringAndTruthy(emmc)) {
    return 'EMMC';
  }
  return null;
}

export function formatTitle(name, display, processor, ssd, hdd, emmc, ram) {
  const formattedDisplay = formatDisplay(display);
  const formattedStorage = getStorageValue(ssd, hdd, emmc);
  const formattedRam = formatMemory(ram);
  const formattedProcessor = processor && processor !== '' ? processor : null;
  const formattedName = `${name} ${formattedDisplay}`;
  const newTitleParts = [formattedProcessor, formattedRam, formattedStorage];

  const formattedTitle = newTitleParts.reduce(
    (prev, curr) => (curr ? `${prev} - ${curr}` : prev),
    formattedName
  );

  return formattedTitle;
}
