import { Fragment } from 'react';
import { isArray } from '../../../../lib/utils';

export default function DetailGridItem({ image, title, value }) {
  // We expect `value` to be an array of formatted strings based on JSON fields.
  // Our utils for formatting those JSON fields will return null if
  // they don't pass validation.
  const shouldDisplay =
    isArray(value) && value.length > 0 && value.indexOf(null) === -1;

  return shouldDisplay ? (
    <div className='flex items-center font-intel-one-text text-theme-intel-2020-e-gray-s2 gap-x-5 sm:pr-3 xl:pr-6'>
      <img src={image} className='w-[60px] h-auto' alt={value} />
      <div>
        <div className='text-srt-base capitalize font-medium'>{title}</div>
        <div className='text-srt-base'>
          {value.map((str, index) =>
            index === 0 ? (
              <Fragment key={str}>{str}</Fragment>
            ) : (
              str && (
                <Fragment key={str}>
                  <br />
                  {str}
                </Fragment>
              )
            )
          )}
        </div>
      </div>
    </div>
  ) : null;
}
