import { ReactComponent as DigidirectLogo } from '../../images/header/digidirect.svg';
import { ReactComponent as CartIcon } from '../../images/header/cart.svg';
import Container from '../container/Container';

export default function Header() {
  return (
    <header className='bg-theme-intel-2020-e-gray-t1'>
      <Container>
        <div className='flex justify-between py-2.5 md:py-5 lg:py-[30px]'>
          <span className='sr-only'>DigiDirect Logo</span>
          <DigidirectLogo className='w-[84px] h-auto' />
          <div className='flex flex-col justify-center'>
            <div>
              <span className='sr-only'>Cart Icon</span>
              <CartIcon className='w-[22px] h-auto fill-theme-intel-2020-e-gray-s2' />
            </div>
            <span className='hidden xl:inline uppercase font-intel-one-text text-srt-xxxs font-medium text-theme-intel-2020-e-gray-s2'>
              $xx.xx
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
}
