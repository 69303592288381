import Container from '../../components/container/Container';

export default function NotFound({
  message = 'Sorry, this page is unavailable.',
  children
}) {
  return (
    <main className='font-intel-one-display flex-shrink-0 flex-grow basis-full flex items-center'>
      <Container>
        <div className='flex flex-col text-center items-center justify-center gap-y-4'>
          <h1 className='text-intel-black text-[24px] leading-[28px] sm:text-srt-4xl font-light'>
            {message}
          </h1>
          <h2 className='text-intel-black font-medium text-[48px] sm:text-[72px] leading-[56px] sm:leading-[100px]'>
            404
          </h2>
          {children}
        </div>
      </Container>
    </main>
  );
}
