import { Navigate } from 'react-router-dom';
import ErrorMessage from '../error-message/ErrorMessage';

export default function FirstProduct({ firstProductId }) {
  if (firstProductId) {
    return <Navigate to={`products/${firstProductId}`} replace />;
  }

  return (
    <ErrorMessage>
      Invalid product ID. Please check the data source for products.
    </ErrorMessage>
  );
}
