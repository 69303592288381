import { Fragment } from 'react';
import ColHeaderDefault from './header/default/ColHeaderDefault';
import ColHeaderFirst from './header/first/ColHeaderFirst';
import ColFirst from './row/first/ColFirst';
import ColDefault from './row/default/ColDefault';
import { formatGameTitle } from '../../../../lib/utils';

export default function GamingFpsGrid({ gameFPS }) {
  return (
    <div className='font-intel-one-text -mx-5 md:mx-0'>
      <div className='grid grid-cols-6 gap-x-0.5 bg-white border-l border-r border-white'>
        <ColHeaderFirst>Game</ColHeaderFirst>
        <ColHeaderDefault>1080p Ultra</ColHeaderDefault>
        <ColHeaderDefault>1440p Ultra</ColHeaderDefault>
        <ColHeaderDefault>4K Medium</ColHeaderDefault>
        <ColHeaderDefault>4K Ultra</ColHeaderDefault>
        <div className='col-span-6 h-px bg-white' />
        {Object.entries(gameFPS).map(([title, data], index) => {
          const formattedGameTitle = formatGameTitle(title);
          return formattedGameTitle ? (
            <Fragment key={title}>
              <ColFirst evenRow={index % 2 === 0}>
                {formattedGameTitle}
              </ColFirst>
              {Object.keys(data).map(
                (key) =>
                  formatGameTitle(title) && (
                    <Fragment key={`${title}-${key}`}>
                      {key !== 'image' && (
                        <ColDefault evenRow={index % 2 === 0}>
                          <div className='flex gap-x-0.5 md:gap-x-2.5 items-center'>
                            <div className='text-srt-table-base md:text-srt-table-lg font-medium'>
                              {data[key] === '-1' ? 'N/A' : data[key]}
                            </div>
                            {data[key] === '-1' ? null : (
                              <div className='text-srt-table-xs md:text-srt-table-sm font-normal text-theme-intel-2020-e-gray-s1'>
                                FPS
                              </div>
                            )}
                          </div>
                        </ColDefault>
                      )}
                    </Fragment>
                  )
              )}
              <div className='col-span-6 h-px bg-white' />
            </Fragment>
          ) : null;
        })}
      </div>
    </div>
  );
}
