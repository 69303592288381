import { useParams } from 'react-router-dom';
import {
  formatBatteryLife,
  formatDisplay,
  formatNumPorts,
  formatRam,
  formatResolution,
  formatWeight,
  getStorageType,
  getStorageValue,
  isStringAndTruthy
} from '../../../lib/utils';
import TechSpecsGrid from './tech-specs-grid/TechSpecsGrid';
import SectionHeadingDefault from '../../typo/section-heading/default/SectionHeadingDefault';
import SectionContainerDefault from '../../container/section-container/default/SectionContainerDefault';

export default function TechSpecs({
  processorFamilyRaw,
  discreteGraphics,
  discreteGraphicsModel,
  os,
  ram,
  ssd,
  hdd,
  emmc,
  display,
  resolution,
  thunderbolt4Ports,
  topWifi,
  batteryLife,
  // connections,
  backlitKeyboard,
  weight,
  config,
  formFactor
}) {
  
  const params = useParams();
  const appName = params?.appName;
  const formattedStorageValue = getStorageValue(ssd, hdd, emmc);
  const formattedStorageType = getStorageType(ssd, hdd, emmc);
  const formattedRam = formatRam(ram);
  const formattedBatteryLife = formatBatteryLife(batteryLife);
  const formattedDisplay = formatDisplay(display);
  const formattedResolution = formatResolution(resolution);
  const formattedProcessorFamilyRaw = isStringAndTruthy(processorFamilyRaw)
    ? processorFamilyRaw
    : null;
  const formattedFormFactor = isStringAndTruthy(formFactor) ? formFactor : null;
  const formattedOS = isStringAndTruthy(os) ? os : null;
  const formattedDiscreteGraphicsModel = discreteGraphics
    ? discreteGraphicsModel
    : 'Integrated';
  // TODO: Field `thunderbolt4` is unreliable in current data feed.
  // Example:
  // "thunderbolt4": false,
  // "thunderbolt1_ports": 0,
  // "thunderbolt2_ports": 0,
  // "thunderbolt3_ports": 0,
  // "thunderbolt4_ports": "2",
  // Determine if data should be updated or if only the `thunderbolt4_ports`
  // field should be relied upon.
  const formattedThunderboltPorts = formatNumPorts(thunderbolt4Ports);
  const formattedTopWifi = isStringAndTruthy(topWifi) ? topWifi : null;
  const formattedBacklitKeyboard = backlitKeyboard ? 'Yes' : 'No';
  let formattedWeight = formatWeight(weight, config);
  // console.log('formattedWeight ->',formattedWeight);
  if (appName === 'rec') {
    if(formattedWeight === null || formattedWeight === '' || formattedWeight === ''){
      formattedWeight = 'N/A';
    }
    else{
      formattedWeight = [...formattedWeight]
        .map((ch) => (ch === '.' ? ',' : ch))
        .join('');
    }
  }
  // eslint-disable-next-line
  
  // TODO: Connections is an array - if using this field,
  // how many connections will we display and how?
  // Unclear from the current wireframe examples.
  // const formattedConnections = connections?.length > 0 && connections;

  const specsGridItems = [
    {
      label: 'Processor',
      specs: [{ name: 'CPU', value: formattedProcessorFamilyRaw }],
      show: formattedProcessorFamilyRaw
    },
    {
      label: 'Graphics',
      specs: [{ name: 'GPU', value: formattedDiscreteGraphicsModel }],
      show: formattedDiscreteGraphicsModel
    },
    {
      label: 'Operating System',
      specs: [{ name: 'OS', value: formattedOS }],
      show: formattedOS
    },
    {
      label: 'Storage',
      specs: [{ name: formattedStorageType, value: formattedStorageValue }],
      show: formattedStorageValue
    },
    {
      label: 'Memory',
      specs: [{ name: 'RAM', value: formattedRam }],
      show: formattedRam
    },
    {
      label: 'I/O',
      specs: [
        { name: 'Thunderbolt 4', value: formattedThunderboltPorts },
        { name: 'Wi-Fi', value: formattedTopWifi }
      ],
      show: formattedThunderboltPorts || formattedTopWifi
    },
    {
      label: 'Display/Monitor',
      specs: [
        { name: 'Display', value: formattedDisplay },
        { name: 'Resolution', value: formattedResolution }
      ],
      show: formattedDisplay || formattedResolution
    },
    {
      label: 'Physical Attributes',
      specs: [
        { name: 'Form Factor', value: formattedFormFactor },
        { name: 'Backlit Keyboard', value: formattedBacklitKeyboard },
        { name: 'Weight', value: formattedWeight },
        { name: 'Battery Life', value: formattedBatteryLife }
      ],
      show:
        formattedFormFactor ||
        formattedBacklitKeyboard ||
        formattedWeight ||
        formattedBatteryLife
    }
  ];
  return (
    <section>
      <div className='mb-16 mb:mb-20'>
        <SectionContainerDefault>
          <SectionHeadingDefault>Technical Specs</SectionHeadingDefault>
          <TechSpecsGrid items={specsGridItems} />
        </SectionContainerDefault>
      </div>
    </section>
  );
}
