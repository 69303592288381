import SectionContainerAlt from '../../container/section-container/alt/SectionContainerAlt';
import SectionHeadingAlt from '../../typo/section-heading/alt/SectionHeadingAlt';
import GamingFpsGrid from './gaming-fps-grid/GamingFpsGrid';

export default function GamingFps({ gameFPS }) {
  return gameFPS ? (
    <section>
      <SectionContainerAlt>
        <SectionHeadingAlt>Gaming FPS</SectionHeadingAlt>
        <GamingFpsGrid gameFPS={gameFPS} />
      </SectionContainerAlt>
    </section>
  ) : null;
}
