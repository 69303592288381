import { Fragment } from 'react';

export default function TechSpecsGrid({ items }) {
  return (
    <div className='w-full font-intel-one-text lg:w-[calc(100%-176px)] xl:w-[calc(100%-416px)]'>
      {items.map(
        (item) =>
          item.show && (
            <div
              key={item.label}
              className='grid grid-cols-2 gap-y-4 md:grid-cols-4 border-b border-theme-intel-2020-e-gray-t1 py-3'
            >
              <div className='pr-8 col-span-2 grid grid-cols-1 md:content-center md:col-span-1'>
                <div className='text-srt-base font-medium text-theme-intel-2020-e-gray'>
                  {item.label}
                </div>
              </div>
              <div className='grid grid-cols-2 col-span-2 gap-y-2 md:gap-y-4 md:col-span-3 md:grid-cols-3'>
                {item.specs.map(
                  (spec) =>
                    spec.value && (
                      <Fragment key={spec.name}>
                        <div className='col-span-1 md:col-span-1 pr-8'>
                          <div className='text-srt-base text-theme-intel-2020-e-gray'>
                            {spec.name}
                          </div>
                        </div>
                        <div className='col-span-1 md:col-span-2 pr-8'>
                          <div className='text-srt-base text-theme-intel-black'>
                            {spec.value}
                          </div>
                        </div>
                      </Fragment>
                    )
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
}
