/* eslint-disable no-console */
import { Route, Routes, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import ProductsDirectory from '../products-directory/ProductsDirectory';
import Product from '../product/Product';
import FirstProduct from '../first-product/FirstProduct';
import NotFound from '../404/NotFound';
import { validProductsJSON } from '../../lib/utils';
import ErrorMessage from '../error-message/ErrorMessage';

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function AppPage() {
  // Get the productId param from the URL.
  const { appName } = useParams();

  const [products, setProducts] = useState(null);
  // Use our own loading state instead of SWR so we can
  // wait until we filter the products list...
  const [isLoading, setIsLoading] = useState(true);
  const [productsJSONIsValid, setProductsJSONIsValid] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { data : configData, error: configError } = useSWR(
    `${process.env.PUBLIC_URL}/data/${appName}/config.json`,
    fetcher
  );
  const {  data, error } = useSWR(
    `${process.env.PUBLIC_URL}/data/${appName}/products.json`,
    fetcher
  );
  // if (config || configerror) {
    
  // }

  // Once we get data, filter products to include
  useEffect(() => {
    if (data || error) {
      if (data !== null) {
        if(configData !== null){
          
          data.config = configData;


        }
        
        const isValid = validProductsJSON(data);
        // if we have valid data..
        if (isValid) {
          // 3/14/22 NOTE: Commenting out filtering logic below as we aren't using for now.
          // filter our unwanted products and update our products state...
          // const productsToInclude = Object.entries(data).reduce(
          //   (prev, curr) => {
          //     const [id, details] = curr;

          //     const shouldInclude = shouldIncludeProduct(details);
          //     return shouldInclude
          //       ? {
          //           ...prev,
          //           [id]: details
          //         }
          //       : prev;
          //   },
          //   {}
          // );
          // setProducts(productsToInclude);
          setProducts(data);
          setProductsJSONIsValid(true);
          
        }
      }
      setIsLoading(false);
    }
  }, [data, error]);

  const appProps = useMemo(
    () => ({
      products,
      isLoading,
      error
    }),
    [products, isLoading, error]
  );

  if (isLoading) return null;

  if (error) {
    if (data === undefined) {
      return <NotFound />;
    }
    <ErrorMessage message='There was an error fetching products data. Failed to load products.' />;
  }

  if (!productsJSONIsValid) {
    return (
      <ErrorMessage message='Invalid schema. Please check the data source.' />
    );
  }

  if (productsJSONIsValid) {
    // console.log(appProps.products)
    
    const firstProductId = Object.keys(products)[0];
    return (
      <Routes>
        <Route
          path=''
          element={<FirstProduct firstProductId={firstProductId} />}
        />
        <Route
          path='products/:productId'
          element={
            <Product
              products={appProps.products}
              isLoading={appProps.isLoading}
              error={appProps.error}
            />
          }
        />
        <Route
          path='srt/pages/admin-view'
          element={<ProductsDirectory products={products} />}
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
    );
  }

  return <NotFound />;
}
