import { Link, useParams } from 'react-router-dom';
import Container from '../../components/container/Container';

export default function ProductsDirectory({ products }) {
  const productIds = Object.keys(products);
  const { appName } = useParams();

  return (
    <Container>
      <div className='grid gap-8'>
        <Link
          className='text-blue-500 hover:text-blue-500 hover:underline'
          to={`${appName}`}
        >
          Home
        </Link>
        <h2 className='text-2xl font-bold'>All Products</h2>
        {productIds.map((id) => (
          <div key={id}>
            <Link
              className='text-blue-500 hover:text-blue-500 hover:underline'
              to={`/${appName}/products/${id}`}
            >
              {id}
            </Link>
          </div>
        ))}
      </div>
    </Container>
  );
}
