import Container from '../container/Container';

export default function Footer() {
  return (
    <footer className='bg-theme-intel-2020-e-gray-t2 text-theme-intel-2020-e-gray-s2 pt-10 pb-32 mt-auto'>
      <Container>
        <div className='grid grid-cols-1 gap-y-4'>
          <p>&copy; Intel Corporation</p>
          <p>
            Intel technologies may require enabled hardware, software or service
            activation. // No product or component can be absolutely secure. //
            Your costs and results may vary. // Performance varies by use,
            configuration and other factors. // See our complete legal Notices
            and Disclaimers. // Intel is committed to respecting human rights
            and avoiding complicity in human rights abuses. See Intel’s Global
            Human Rights Principles. Intel’s products and software are intended
            only to be used in applications that do not cause or contribute to a
            violation of an internationally recognized human right.
          </p>
        </div>
      </Container>
    </footer>
  );
}
