import Windows10Icon from '../../../images/details/os/windows10.svg';
import Windows11Icon from '../../../images/details/os/windows11.svg';
import IntelCoreI7Icon from '../../../images/details/processors/core/i7.png';
import IntelCoreI5Icon from '../../../images/details/processors/core/i5.png';
import IntelCoreI3Icon from '../../../images/details/processors/core/i3.png';
import PentiumIcon from '../../../images/details/processors/pentium/pentium.png';
import CeleronIcon from '../../../images/details/processors/celeron/celeron.png';
import ArcIcon from '../../../images/details/graphics/arc.png';
import DiscreteGraphicsDefaultIcon from '../../../images/details/graphics/discrete_graphics.svg';
import IntegratedGraphicsIcon from '../../../images/details/graphics/integrated_graphics.svg';
import IrisXeIcon from '../../../images/details/graphics/iris_xe.png';
import IrisXeMaxIcon from '../../../images/details/graphics/iris_xe_max.png';
import IntelEvoI9Icon from '../../../images/details/processors/evo/evo_i9.png';
import IntelEvoI7Icon from '../../../images/details/processors/evo/evo_i7.png';
import IntelEvoI5Icon from '../../../images/details/processors/evo/evo_i5.png';
import RamIcon from '../../../images/details/ram/ram.svg';
import SsdIcon from '../../../images/details/storage/ssd.svg';
import HddIcon from '../../../images/details/storage/hdd.svg';
import EmmcIcon from '../../../images/details/storage/emmc.svg';
import DisplayIcon from '../../../images/details/display/display.svg';
import ThunderboltIcon from '../../../images/details/thunderbolt/thunderbolt.svg';
import BatteryIcon from '../../../images/details/battery/battery.svg';
import WifiDefaultIcon from '../../../images/details/wifi/generic.svg';
import Wifi6eIcon from '../../../images/details/wifi/wifi6e.png';
import DetailGrid from './detail-grid/DetailGrid';
import {
  formatBatteryLife,
  formatDisplay,
  formatNumPorts,
  formatRam,
  formatResolution,
  getStorageValue,
  isStringAndTruthy
} from '../../../lib/utils';
import SectionContainer from '../../container/section-container/default/SectionContainerDefault';
import SectionHeadingDefault from '../../typo/section-heading/default/SectionHeadingDefault';

function getProcessorIcon(evo, processor) {
  if (!isStringAndTruthy(processor)) {
    return null;
  }

  // TODO: Determine significance of `evo` field.
  // Determine what icons aren't applicable.
  // `Evo` not found in any processor name.
  // `Atom` not found in any processor name.
  // `vPro` not found in any processor name.
  // The above processor icons are therefore currently not used.

  const i9 = /Intel.*Core.*i9/g;
  const i7 = /Intel.*Core.*i7/g;
  const i5 = /Intel.*Core.*i5/g;
  const i3 = /Intel.*Core.*i3/g;
  if (processor.match(i9)) {
    // TODO: Is i9 always `Evo`?
    // Icon not available for `Intel Core i9` only `Evo` version.
    // Below is redundant and placeholder...
    return evo ? IntelEvoI9Icon : IntelEvoI9Icon;
  }
  if (processor.match(i7)) {
    return evo ? IntelEvoI7Icon : IntelCoreI7Icon;
  }
  if (processor.match(i5)) {
    return evo ? IntelEvoI5Icon : IntelCoreI5Icon;
  }
  if (processor.match(i3)) {
    return IntelCoreI3Icon;
  }

  // 3/8/23 NOTE: For now, we are filtering out any product that has
  // a Pentium or Celeron process before we load products.
  // Therefore, the filters below won't be applicable.
  const pentium = /Intel.*Pentium.*/g;
  if (processor.match(pentium)) {
    return PentiumIcon;
  }

  const celeron = /Intel.*Celeron.*/g;
  if (processor.match(celeron)) {
    return CeleronIcon;
  }

  return null;
}

function getOsIcon(os) {
  if (!isStringAndTruthy(os)) {
    return null;
  }
  // 3/8/23 NOTE: For now, we are filtering out any product that has
  // Windows 10 before we load products.
  // Therefore, the filter below won't be applicable.
  if (os.includes('Windows 10')) {
    return Windows10Icon;
  }
  if (os.includes('Windows 11')) {
    return Windows11Icon;
  }
  return null;
}

function getGraphicsIcon(graphics) {
  const arc = /Intel.*Arc.*/g;
  const irisXeMax = /Intel.*Iris.*Xe.*Max.*/g;
  const irisXe = /Intel.*Iris.*Xe/g;

  if (!isStringAndTruthy(graphics)) {
    return null;
  }
  if (graphics.match(arc)) {
    return ArcIcon;
  }
  if (graphics.match(irisXeMax)) {
    return IrisXeMaxIcon;
  }
  if (graphics.match(irisXe)) {
    return IrisXeIcon;
  }
  return DiscreteGraphicsDefaultIcon;
}

function getStorageIcon(ssd, hdd, emmc) {
  if (isStringAndTruthy(ssd)) {
    return SsdIcon;
  }
  if (isStringAndTruthy(hdd)) {
    return HddIcon;
  }
  if (isStringAndTruthy(emmc)) {
    return EmmcIcon;
  }
  return null;
}

function getWifiIcon(topWifi) {
  if (!isStringAndTruthy(topWifi)) {
    return null;
  }
  const wifi6e = /Wi-Fi 6E.*/g;

  if (topWifi.match(wifi6e)) {
    return Wifi6eIcon;
  }

  return WifiDefaultIcon;
}

export default function Details({
  processor,
  processorFamilyRaw,
  discreteGraphics,
  discreteGraphicsModel,
  os,
  evo,
  ram,
  ssd,
  hdd,
  emmc,
  display,
  resolution,
  thunderbolt4Ports,
  topWifi,
  batteryLife
}) {
  const osIcon = getOsIcon(os);
  const processorIcon = getProcessorIcon(evo, processor);
  const graphicsIcon = discreteGraphics
    ? getGraphicsIcon(discreteGraphicsModel)
    : IntegratedGraphicsIcon;
  const ramValue = formatRam(ram);
  const ramIcon = ramValue && RamIcon;
  const storageIcon = getStorageIcon(ssd, hdd, emmc);
  const storageValue = getStorageValue(ssd, hdd, emmc);
  const formattedDisplay = formatDisplay(display);
  const formattedResolution = formatResolution(resolution);
  const displayIcon = formattedDisplay && DisplayIcon;
  const formattedNumPorts = formatNumPorts(thunderbolt4Ports);
  const thunderboltIcon = formattedNumPorts && ThunderboltIcon;
  const wifiIcon = getWifiIcon(topWifi);
  const formattedBatteryLife = formatBatteryLife(batteryLife);
  const batteryIcon = formattedBatteryLife && BatteryIcon;

  const detailGridItems = [
    {
      title: 'Processor',
      value: [processorFamilyRaw],
      image: processorIcon
    },
    {
      title: 'Graphics',
      value: discreteGraphics ? [discreteGraphicsModel] : ['Integrated'],
      image: graphicsIcon
    },
    { title: 'OS', value: [os], image: osIcon },
    { title: 'Memory', value: [ramValue], image: ramIcon },
    {
      title: 'Storage',
      value: [storageValue],
      image: storageIcon
    },
    {
      title: 'Display',
      value: [formattedDisplay, formattedResolution],
      image: displayIcon
    },
    {
      title: 'Thunderbolt™ Technology',
      value: [formattedNumPorts],
      image: thunderboltIcon
    },
    { title: 'Wi-Fi', value: [topWifi], image: wifiIcon },
    { title: 'Battery Life', value: [formattedBatteryLife], image: batteryIcon }
  ];

  return (
    <section className='grid grid-cols-1'>
      <SectionContainer>
        <div className='grid grid-cols-1 gap-y-12 md:gap-y-20'>
          <SectionHeadingDefault>Product Details</SectionHeadingDefault>
          <DetailGrid items={detailGridItems} />
        </div>
      </SectionContainer>
    </section>
  );
}
