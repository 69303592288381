import { Link, useParams } from 'react-router-dom';
import Hero from '../../components/product/hero/Hero';
import Details from '../../components/product/details/Details';
import TechSpecs from '../../components/product/tech-specs/TechSpecs';
import GamingFps from '../../components/product/gaming-fps/GamingFps';
import Nav from '../../components/product/nav/Nav';
import NotFound from '../404/NotFound';
// import { REACT_APP_WEIGHT } from '../../../.env.production';

export default function Product({ products }) {
  const { productId, appName } = useParams();
  

  const firstProductId = Object.keys(products)[0];
  const product = products[productId];
  const message = `Sorry, this page is unavailable. No data found for product with ID: ${productId}.`;

  if (!product) {
    return (
      <NotFound message={message}>
        {firstProductId && typeof firstProductId === 'string' && (
          <button
            type='button'
            className='bg-theme-intel bg-theme-intel-2020-e-gray-s1 py-2 px-10 rounded-[4px]'
          >
            <Link
              className='text-white text-srt-xs font-medium flex items-center'
              to={`/${appName}/products/${firstProductId}`}
            >
              Go back to products
            </Link>
          </button>
        )}
      </NotFound>
    );
  }

  const {
    brand,
    name,
    price,
    currency,
    display,
    resolution,
    processor,
    evo,
    ssd,
    hdd,
    emmc,
    ram,
    image,
    sku,
    os,
    connections,
    weight,
    country,
  } = product;

  const discreteGraphics = product?.discrete_graphics;
  const discreteGraphicsModel = product?.discrete_graphics_model;
  const batteryLife = product?.battery_life;
  const topWifi = product?.top_wifi;
  const processorFamilyRaw = product?.processor_family_raw;
  const thunderbolt4Ports = product?.thunderbolt4_ports;
  const formFactor = product?.form_factor;
  const backlitKeyboard = product?.backlit_keyboard;
  const gameFPS = product?.game_fps;
  // console.log(process.env.NODE_ENV)
  return (
    <main>
    {(products.config.navigation === true) &&
      <Nav products={products} appName={appName} productId={productId} />
    }
      <Hero
        name={name}
        price={price}
        currency={currency}
        display={display}
        processor={processor}
        ssd={ssd}
        ram={ram}
        image={image}
        sku={sku}
        brand={brand}
        country={country}
      />
      <Details
        processor={processor}
        processorFamilyRaw={processorFamilyRaw}
        evo={evo}
        discreteGraphics={discreteGraphics}
        discreteGraphicsModel={discreteGraphicsModel}
        os={os}
        ram={ram}
        ssd={ssd}
        hdd={hdd}
        emmc={emmc}
        display={display}
        resolution={resolution}
        thunderbolt4Ports={thunderbolt4Ports}
        topWifi={topWifi}
        batteryLife={batteryLife}
      />
      <GamingFps gameFPS={gameFPS} />
      <TechSpecs
        processor={processor}
        processorFamilyRaw={processorFamilyRaw}
        discreteGraphics={discreteGraphics}
        discreteGraphicsModel={discreteGraphicsModel}
        os={os}
        ram={ram}
        ssd={ssd}
        hdd={hdd}
        emmc={emmc}
        display={display}
        resolution={resolution}
        thunderbolt4Ports={thunderbolt4Ports}
        topWifi={topWifi}
        batteryLife={batteryLife}
        connections={connections}
        weight={weight}
        config={products.config}
        backlitKeyboard={backlitKeyboard}
        formFactor={formFactor}
      />
    </main>
  );
}
